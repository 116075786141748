import {EEventState, EEventType, Event, EventAccess} from './../../../../data/event.data';
import {
  AlarmGroupAccess,
  CalendarAccess,
  DatabasePersonWithAccess,
  EAccessPermission,
  ECalendarType,
  EFeedbackPermission
} from './../../../../data/calendar.data';
import CalendarService from '../../../../services/calendar.service';
import {ILogService} from 'angular';
import {PersonSearchQueryResult} from '../../../../data/person.data';

import {Calendar, EventInput} from '@fullcalendar/core';
import listPlugin from '@fullcalendar/list';
import deLocale from '@fullcalendar/core/locales/de';
import {SpinnerRules} from '../event.modal/event.modal';
import PrivilegeService from '../../../../services/privilege.service';
import {RolePrivilege} from '../../../../data/privileges.enum';
import angular = require("angular");

'use strict';

require('./calendar.modal.css');

/* @ngInject */
export default class CalendarModalController {
  public $uibModalInstance: any;
  public $rootScope: angular.IRootScopeService;
  public $scope: angular.IScope;
  public $log: ILogService;
  public okFunction: any;
  public deleteFunction: any;
  public calendarAccess: CalendarAccess;
  public calendarAccessOrig: CalendarAccess;
  public isNew: boolean;
  public activeTabIndex = 0;
  public restService: any;
  public dataService: any;
  public calendarService: CalendarService;
  public isLoading: boolean = false;
  public isDirty: boolean = false;
  public loadingEvents: boolean = false;
  public $uibModal;
  public startTime: Date;
  public endTime: Date;
  public mode = CalendarModes.DETAILS;
  public startTimeValid = true;
  public endTimeValid = true;
  public copiedToClipboard: boolean;

  public calendar: Calendar;

  public rulesMax = {
    max: Number.MAX_SAFE_INTEGER,
    min: 0
  } as SpinnerRules;
  public rulesMin = {
    max: Number.MAX_SAFE_INTEGER,
    min: 0
  } as SpinnerRules;


  constructor($uibModalInstance, $uibModal, $scope: angular.IScope, $rootScope: angular.IRootScopeService, dataService, restService, $log: ILogService, okFunction, calendar: CalendarAccess, isNew: boolean, calendarService: CalendarService,
    public privilegeService: PrivilegeService) {
    this.$uibModalInstance = $uibModalInstance;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$log = $log;
    this.okFunction = okFunction;
    this.calendarAccess = calendar;
    this.calendarAccessOrig = angular.copy(this.calendarAccess);
    this.isNew = isNew;
    this.restService = restService;
    this.dataService = dataService;
    this.calendarService = calendarService;
    this.$uibModal = $uibModal;

    //Only load data from FE2 if it's not a new calendar
    if (!isNew) {
      this.init();
    }
  }

  changeDefaultWithFeedback() {
    if (this.isNew) {
      this.calendarAccess.calendar.defaultWithFeedback = !this.calendarAccess.calendar.defaultWithFeedback;
    }
  }

  initCalendarWidget() {
    if (this.calendar) return;
    const calendarEl = document.getElementById('calendarList');

    this.calendar = new Calendar(calendarEl, {
      plugins: [listPlugin],
      headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: ''
      },
      initialView: 'listMonth',
      weekNumbers: true,
      weekText: '',
      navLinks: true, // can click day/week names to navigate views
      editable: true,
      locale: deLocale,
      dayMaxEvents: true,
      contentHeight: 'auto'
    });
    this.calendar.render();
  }

  // used to set a css class for text transformation to uppercase for token
  isNotEmail(identifier: string) {
    return !identifier.includes('@');
  }

  init() {
    this.isLoading = true;
    const calendarId = this.calendarAccess.calendar.id;
    this.calendarService.getCalendarById(calendarId).then((response: CalendarAccess) => {
      this.isDirty = false;
      this.$log.info('Got calendar:');
      this.$log.info(response);

      this.calendarAccess = response;
      if (!this.privilegeService.has(RolePrivilege.EventPlanning_Appointment)) {
        return;
      }

      // Convert local time sting to Date object
      if (response.calendar.defaultStartTime) {
        this.startTime = new Date()
        const tmp = new Date('1970-01-01T' + response.calendar.defaultStartTime + 'Z');
        this.startTime.setHours(tmp.getUTCHours());
        this.startTime.setMinutes(tmp.getMinutes());
        this.startTime.setSeconds(0);
        this.startTime.setMilliseconds(0);
      }
      if (response.calendar.defaultEndTime) {
        const tmp = new Date('1970-01-01T' + response.calendar.defaultEndTime + 'Z');
        this.endTime = new Date();
        this.endTime.setHours(tmp.getUTCHours());
        this.endTime.setMinutes(tmp.getMinutes());
        this.endTime.setSeconds(0);
        this.endTime.setMilliseconds(0);
      }
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$apply();
    });
  }

  cancel() {
    this.calendarAccess.calendar.name = this.calendarAccessOrig.calendar.name;
    this.calendarAccess.calendar.type = this.calendarAccessOrig.calendar.type;
    this.calendarAccess.calendar.defaultMinParticipants = this.calendarAccessOrig.calendar.defaultMinParticipants;
    this.calendarAccess.calendar.defaultMaxParticipants = this.calendarAccessOrig.calendar.defaultMaxParticipants;
    this.calendarAccess.calendar.defaultResponsiblePerson = this.calendarAccessOrig.calendar.defaultResponsiblePerson;
    this.calendarAccess.calendar.defaultStartTime = this.calendarAccessOrig.calendar.defaultStartTime;
    this.calendarAccess.calendar.defaultEndTime = this.calendarAccessOrig.calendar.defaultEndTime;
    this.calendarAccess.calendar.color = this.calendarAccessOrig.calendar.color;

    this.$uibModalInstance.close();
  }


  /**
   * Save an existing calendar
   */
  save() {
    this.isLoading = true;

    if (this.isNew) {
      // Create
      this.calendarService.createCalendar(this.calendarAccess).then((calendar) => {
        // Reload calendars
        this.okFunction();
        this.calendarAccess = calendar;
      }).catch(error => {
        this.$log.error('Calendar creation failed', error);
      }).finally(() => {
        this.isDirty = false;
        this.isLoading = false;
        this.isNew = false;
        this.$scope.$apply();
      });
    } else {
      // Update
      this.calendarService.saveCalendar(this.calendarAccess).then((calendar) => {
        // Reload calendars
        this.okFunction();
        this.calendarAccess = calendar;
      }).catch(error => {
        this.$log.error('Could not save calendar', error);
      }).finally(() => {
        this.isDirty = false;
        this.isLoading = false;
        this.$scope.$apply();
      });
    }
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.calendarAccess.calendar.publicIcalUrl).then(() => {
      this.copiedToClipboard = true;
      this.$scope.$applyAsync();
    });
  }

  /**
   * Safely delete existing calendar
   */
  delete() {
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;

            this.calendarService.deleteCalendarById(this.calendarAccess.calendar.id).then(() => {
              // Reload calendars and close modal
              this.okFunction();
              this.$uibModalInstance.close();
            }).catch(error => {
              this.$log.error('Error deleting calendar', error);
              this.isLoading = false;
            });
          };
        },
        additionalText: () => {
          return;
        }
      }
    });
  }

  /**
   * Savely delete past events
   */
  deletePastEvents() {
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;

            this.calendarService.deleteEventByState(this.calendarAccess.calendar.id, EEventState.COMPLETED).then(() => {
              // Reload calendars and close modal

              this.$rootScope.$broadcast('events.updated');

              this.okFunction();
              this.$uibModalInstance.close();
            }).catch(error => {
              this.$log.error('Error deleting past events', error);
              this.isLoading = false;
            });
          };
        },
        additionalText: () => {
          return 'CALENDAR.DELETE_PAST_EVENTS_HINT';
        }
      }
    });
  }

  switchType(type: ECalendarType) {
    this.calendarAccess.calendar.type = type;
  }

  toggleAlarmGroupSelection(alarmGroup: AlarmGroupAccess) {
    alarmGroup.selected = !alarmGroup.selected;
    this.isDirty = true;
  }

  /**
   * Auto complete persons
   */
  getPersons(val: string) {
    return this.restService.searchForPersons(val);
  }

  /**
   * Select person
   */
  personSelected(person: PersonSearchQueryResult) {
    this.calendarAccess.calendar.defaultResponsiblePerson = person.displayName;
  }

  updateStartTime() {
    this.calendarAccess.calendar.defaultStartTime = this.startTime.toLocaleTimeString();
    if (this.startTime < this.endTime) {
      this.startTimeValid = true;
      this.endTimeValid = true;
    } else {
      this.startTimeValid = false;
    }
  }

  updateEndTime() {
    this.calendarAccess.calendar.defaultEndTime = this.endTime.toLocaleTimeString();
    if (this.startTime < this.endTime) {
      this.startTimeValid = true;
      this.endTimeValid = true;
    } else {
      this.endTimeValid = false;
    }
  }


  loadEvents() {
    this.mode = CalendarModes.EVENTS
    this.initCalendarWidget();
    this.calendar.removeAllEvents();

    this.loadingEvents = true;
    // Load events only the first time
    this.calendarService.getEventsForCalendarId(this.calendarAccess.calendar.id).then((response: EventAccess[]) => {
      this.$log.info('Got events:');
      this.$log.info(response);

      response.forEach(eventWithAccess => {
        const event: Event = eventWithAccess.event;

        const eventInput = {
          title: event.title,
          allDay: event.fullDay,
          start: new Date(event.startDate),
          end: new Date(event.endDate),
          backgroundColor: event.color,
          borderColor: event.color,
          color: event.color,
          display: 'block',
          editable: true,
          id: event.id,
          extendedProps: {
            topic: event.topic,
            location: event.location,
            responsiblePerson: event.responsiblePerson
          }
        } as EventInput;

        switch (event.status) {
          case EEventState.COMPLETED:
            eventInput.className = 'past-event';
            break;
          case EEventState.CANCELLED:
            eventInput.className = 'cancelled-event';
            eventInput.borderColor = 'red';
            break;

        }
        this.calendar.addEvent(eventInput);
      });

      this.calendar.render();

    }).finally(() => {
      this.loadingEvents = false;
      this.$scope.$apply();
    });

  }

  openEventModal(event: EventAccess, isNew: boolean) {

    this.$uibModal.open({
      template: require('../../../modals/calendar/event.modal/event.modal.html'),
      controller: 'EventModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        event: () => {
          return event;
        },
        isNew: () => {
          return isNew;
        },
        okFunction: () => {
          return () => {
            this.loadEvents();
            return;
          };
        }
      }
    });
  }

  addEvent(eventType: EEventType) {
    this.calendarService.getEventTemplateForCalendar(this.calendarAccess.calendar.id, eventType, undefined).then((responseData: EventAccess) => {
      this.openEventModal(responseData, true);
    }).catch(error => {
      this.$log.error(error);
    });
  }

  /**
   * Change a persons access
   * @param personAccess
   */
  changePermission(personAccess: DatabasePersonWithAccess) {
    switch (personAccess.accessPermission) {
      case EAccessPermission.NONE:
        personAccess.accessPermission = EAccessPermission.READ;
        break;
      case EAccessPermission.READ:
        if (this.calendarAccess.calendar.icalSyncCalendar) {
          personAccess.accessPermission = EAccessPermission.NONE;
        } else {
          personAccess.accessPermission = EAccessPermission.READ_WRITE;
        }
        break;
      case EAccessPermission.READ_WRITE:
        personAccess.accessPermission = EAccessPermission.NONE;
        break;
      default:
        personAccess.accessPermission = EAccessPermission.NONE;
        break;
    }
  }

  changeFeedbackPermission(personAccess: DatabasePersonWithAccess) {
    switch (personAccess.feedbackPermission) {
      case EFeedbackPermission.NONE:
        personAccess.feedbackPermission = EFeedbackPermission.SIMPLE;
        break;
      case EFeedbackPermission.SIMPLE:
        personAccess.feedbackPermission = EFeedbackPermission.FULL;
        break;
      case EFeedbackPermission.FULL:
        personAccess.feedbackPermission = EFeedbackPermission.NONE;
        break;
      default:
        personAccess.feedbackPermission = EFeedbackPermission.NONE;
        break;
    }
  }

  /**
   * Triggers every time the participants change
   */
  onParticipantsChanged() {
    const cal = this.calendarAccess.calendar;
    if (cal.defaultMinParticipants > 0) {
      this.rulesMax.max = Number.MAX_SAFE_INTEGER;
      if (cal.defaultMaxParticipants !== 0) {
        this.rulesMax.min = cal.defaultMinParticipants;
      } else {
        this.rulesMax.min = 0;
      }
    } else {
      this.rulesMax.max = Number.MAX_SAFE_INTEGER;
      this.rulesMax.min = 0;
    }

    if (cal.defaultMaxParticipants > 0) {
      this.rulesMin.max = cal.defaultMaxParticipants;
      this.rulesMin.min = 0;
    } else {
      this.rulesMin.max = Number.MAX_SAFE_INTEGER;
      this.rulesMin.min = 0;
    }
  }
}

export enum CalendarModes {
  DETAILS = 'DETAILS',
  ACCESS = 'ACCESS',
  EVENTS = 'EVENTS',
  ICAL = 'ICAL'
}